@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "WantedSans";
  src: url("./fonts/WantedSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "WantedSans";
  src: url("./fonts/WantedSans-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "WantedSans";
  src: url("./fonts/WantedSans-Bold.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  background-color: #f4f6f8;
  font-family: "WantedSans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* @media (min-width: 375px) and (max-width: 425px) {
  body {
    zoom: 0.9;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  body {
    zoom: 0.8;
  }
}
@media (max-width: 320px) {
  body {
    zoom: 0.72;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
